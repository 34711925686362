<template>
  <section class="invoice-add-wrapper">
    <b-row
      v-if="invoice"
      class="invoice-add"
    >
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-form @submit.prevent>
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <b-card-body class="invoice-padding pb-0">
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <div>
                  <div class="logo-wrapper">
                    <img
                      src="@/assets/images/logo/logo.png"
                      width="200px"
                      height="auto"
                    >
                    <h3 class="text-primary invoice-logo" />
                  </div>
                  <b-card-text class="mb-0">
                    RUE EL KOUNAITRA 1-dar Chaabene El Fehri
                  </b-card-text>
                  <b-card-text class="mb-0">
                    gsr-cle1@hotmail.fr
                  </b-card-text>
                  <b-card-text class="mb-0">
                    +216 97 607 740
                  </b-card-text>
                  <b-card-text class="mb-0">
                    MF : 1082793F/A/M/000
                  </b-card-text>
                </div>
                <div>
                  <b-row>
                    <b-col>
                      <h4
                        style="color: black"
                        class="text-center"
                      >
                        Bon de sortie N°
                      </h4>
                    </b-col>
                    <b-col>
                      <b-input-group>
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HashIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="invoice-data-id"
                          v-model="invoice.number"
                        />
                      </b-input-group>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col>
                      <h4
                        style="color: black"
                        class="text-center"
                      >
                        Date
                      </h4>
                    </b-col>
                    <b-col>
                      <flat-pickr
                        v-model="createdDate"
                        class="form-control"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col>
                      <h4
                        style="color: black"
                        class="text-center"
                      >
                        Date de début de validité
                      </h4>
                    </b-col>
                    <b-col>
                      <flat-pickr
                        v-model="invoice.date_start"
                        class="form-control"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col>
                      <h4
                        style="color: black"
                        class="text-center"
                      >
                        Date de fin de validité
                      </h4>
                    </b-col>
                    <b-col>
                      <flat-pickr
                        v-model="invoice.date_end"
                        class="form-control"
                      />
                    </b-col>
                  </b-row>

                  <b-row class="mt-1">
                    <b-col>
                      <h4
                        style="color: black"
                        class="text-center"
                      >
                        Secteur
                      </h4></b-col>
                    <b-col>
                      <v-select
                        v-model="invoice.states"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="states"
                        label="name"
                        input-id="invoice-data-client"
                        :clearable="false"
                        :reduce="(state) => state.id"
                        multiple
                      />
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-card-body>
            <!-- Spacer -->
            <hr class="invoice-spacing">
            <!-- Invoice Client & Payment Details -->
            <b-card-body class="invoice-padding pt-0">
              <b-row>
                <!-- Col: Invoice To -->
                <b-col
                  cols="12"
                  xl="6"
                  class="mb-lg-1"
                >
                  <h6 class="mb-1">
                    Chauffeur
                  </h6>
                  <!-- Select Client -->
                  <v-select
                    v-model="invoice.driver"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="drivers"
                    label="name"
                    input-id="invoice-data-client"
                    :clearable="false"
                  />

                  <!-- Selected Client -->
                </b-col>
                <feather-icon
                  size="16"
                  icon="XOctagonIcon"
                  class="cursor-pointer mt-4"
                  @click="invoiceData.driver = null"
                />
                <b-col
                  cols="12"
                  xl="6"
                  class="mb-lg-1"
                >
                  <h6 class="mb-1">
                    Camion
                  </h6>
                  <!-- Select Client -->
                  <v-select
                    v-model="invoice.truck"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="trucks"
                    label="serial_number"
                    input-id="invoice-data-client"
                    :clearable="false"
                  />

                  <!-- Selected Client -->
                </b-col>
                <feather-icon
                  size="16"
                  icon="XOctagonIcon"
                  class="cursor-pointer mt-4"
                  @click="invoiceData.truck = null"
                />
              </b-row>
            </b-card-body>

            <!-- Items Section -->
            <b-card-body class="invoice-padding form-item-section">
              <div
                v-if="isLoading === true"
                class="text-center"
              >
                <b-spinner
                  large
                  variant="primary"
                />
              </div>
              <div
                ref="form"
                class="repeater-form"
                :style="{ height: trHeight }"
              >
                <b-row
                  v-for="(item, index) in invoiceData.items"
                  :key="index"
                  ref="row"
                  class="pb-2"
                >
                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-none d-lg-flex">
                      <b-row class="flex-grow-1 px-1">
                        <!-- Single Item Form Headers -->
                        <b-col
                          cols="12"
                          lg="3"
                        >
                          Référence
                        </b-col>

                        <b-col
                          cols="12"
                          lg="3"
                        >
                          Labelle
                        </b-col>

                        <b-col
                          cols="12"
                          lg="3"
                        >
                          Quantité
                        </b-col>
                        <b-col
                          cols="12"
                          lg="3"
                        >
                          Prix total
                        </b-col>
                      </b-row>
                      <div class="form-item-action-col" />
                    </div>
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <b-col
                          cols="12"
                          lg="3"
                        >
                          <label class="d-inline d-lg-none">Item</label>
                          <v-select
                            v-model="item.produit"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="products"
                            label="reference"
                            :clearable="false"
                            class="mb-2 item-selector-title"
                            placeholder="Select Item"
                            disabled
                          />
                        </b-col>

                        <b-col
                          cols="12"
                          lg="3"
                        >
                          <label class="d-inline d-lg-none">Item</label>
                          <v-select
                            v-model="item.produit"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="products"
                            label="label"
                            :clearable="false"
                            class="mb-2 item-selector-title"
                            placeholder="Select Item"
                            disabled
                          />
                        </b-col>

                        <b-col
                          cols="12"
                          lg="3"
                        >
                          <label class="d-inline d-lg-none">Qty</label>
                          <b-form-input
                            v-model="item.qty"
                            value="1"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>

                        <b-col
                          cols="12"
                          lg="3"
                        >
                          <label class="d-inline d-lg-none">Prix</label>
                          <p class="mb-1">
                            {{ (item.produit.price_ht * item.qty).toFixed(3) }}
                          </p>
                        </b-col>
                      </b-row>
                      <div
                        class="d-flex flex-column justify-content-between border-left py-50 px-25"
                      >
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeOld(index, item)"
                        />
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row
                  v-for="(item, index) in invoiceDataNew.items"
                  :key="index"
                  ref="row"
                  class="pb-2"
                >
                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-none d-lg-flex">
                      <b-row class="flex-grow-1 px-1">
                        <!-- Single Item Form Headers -->
                        
                        <b-col
                          cols="12"
                          lg="6"
                        >
                          Labelle
                        </b-col>

                        <b-col
                          cols="12"
                          lg="3"
                        >
                          Quantité
                        </b-col>
                        <b-col
                          cols="12"
                          lg="3"
                        >
                          Prix total
                        </b-col>
                      </b-row>
                      <div class="form-item-action-col" />
                    </div>

                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <!-- Single Item Form Headers -->
                        <b-col
                          cols="12"
                          lg="6"
                        >
                          <label class="d-inline d-lg-none">Labelle</label>
                          <b-form-input
                            v-if="item.name == ''"
                            v-model="item.name"
                            class="mb-2"
                          />
                          <v-select
                            v-else
                            v-model="item.produit"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="products"
                            label="name"
                            :clearable="false"
                            class="mb-2 item-selector-title"
                            placeholder="Select Item"
                            @input="setSelected(item.produit, index)"
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="3"
                        >
                          <label class="d-inline d-lg-none">Qty</label>
                          <b-form-input
                            v-model="item.qty1"
                            value="1"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>

                        <b-col
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Prix</label>
                          <p class="mb-1">
                            {{
                              (item.produit.sales_price_ht * item.qty1).toFixed(
                                3
                              )
                            }}
                          </p>
                        </b-col>
                      </b-row>
                      <div
                        class="d-flex flex-column justify-content-between border-left py-50 px-25"
                      >
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeNew(index)"
                        />
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="addNewItemInItemForm"
              >
                Ajouter produit
              </b-button>
            </b-card-body>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>
                <!-- Col: Sales Persion -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1"
                />

                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper">
                    <div
                      v-if="somme"
                      class="invoice-total-item"
                    >
                      <p class="invoice-total-title">
                        Total HT:
                      </p>
                      <p class="invoice-total-amount">
                        {{ somme.toFixed(3) }} DT
                      </p>
                    </div>
                    <div
                      v-else
                      class="invoice-total-item"
                    >
                      <p class="invoice-total-title">
                        Total HT:
                      </p>
                      <p class="invoice-total-amount">
                        0
                      </p>
                    </div>

                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Tax 19 %:
                      </p>
                      <p
                        v-if="somme"
                        class="invoice-total-amount"
                      >
                        {{ tax }} DT
                      </p>
                      <p
                        v-else
                        class="invoice-total-amount"
                      >
                        0
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Timbre fiscal:
                      </p>
                      <p class="invoice-total-amount">
                        1 DT
                      </p>
                    </div>
                    <hr class="my-50">
                    <div
                      v-if="somme"
                      class="invoice-total-item"
                    >
                      <p class="invoice-total-title">
                        Prix T.T.C:
                      </p>
                      <p class="invoice-total-amount">
                        {{ sommeTTC }} DT
                      </p>
                    </div>
                    <div
                      v-else
                      class="invoice-total-item"
                    >
                      <p class="invoice-total-title">
                        Prix T.T.C:
                      </p>
                      <p class="invoice-total-amount">
                        0
                      </p>
                    </div>
                  </div></b-col></b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Note -->
            <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold">Note: </span>
              <b-form-textarea v-model="invoiceData.note" />
            </b-card-body>
          </b-card>
        </b-form>
      </b-col>

      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >
        <b-card>
          <b-button
            type="submit"
            variant="primary"
            class="mr-1"
            @click="update"
          >
            <b-spinner
              v-if="isLoading === true"
              small
            />
            Enregistrer
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { ref } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  VBToggle,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axiosIns from '@/libs/axios'

export default {
  name: 'EditExitVoucher',
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    flatPickr,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BSpinner,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  data() {
    return {
      isLoading: true,
      invoice: '',
      status: '',
      drivers: [],
      trucks: [],
      states: [],
      last: [],
      removedItem: [],
      createdDate: null,
    }
  },

  computed: {
    generate() {
      return this.last + 1
    },
    somme() {
      const s = this.invoiceData.items.reduce(
        (accumulator, item) => accumulator + item.produit.price_ht * item.qty,
        0,
      )
      if (this.invoiceDataNew.length !== 0) {
        const n = this.invoiceDataNew.items.reduce(
          (accumulator, item) => accumulator + item.produit.sales_price_ht * item.qty1,
          0,
        )

        return s + n
      }
      return s
    },
    sommeTTC() {
      return (Number(this.somme) * (1 + 19 / 100) + 1).toFixed(3)
    },
    tax() {
      return (Number(this.somme) / (100 / 19)).toFixed(3)
    },
  },

  mounted() {
    this.initTrHeight()
  },
  created() {
    this.getExitVoucher()
    this.getTrucks()
    this.getStates()
    this.getDrivers()
    this.getLastExit()

    this.invoiceData.items.shift()
    this.invoiceDataNew.items.shift()

    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    setSelected(value, index) {
      const f = this.invoiceDataNew.items.filter(
        item => item.produit.reference === value.reference,
      )
      if (f.length > 1) {
        this.withTitle1(index, f)
      } else {
        f[0].qty1 = 1
      }
      const f1 = this.invoiceData.items.filter(
        item => item.produit.reference === value.reference,
      )
      if (f1.length >= 1) {
        this.withTitle(index, f1)
      } else {
        f1[0].qty = 1
      }
    },

    removeNew(index) {
      this.invoiceDataNew.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    async getLastExit() {
      const { data } = await axiosIns.get('/exit-vouchers/last/')
      this.last = data
    },

    async getStates() {
      try {
        const { data } = await axiosIns.get('/states')
        this.states = data
      } catch (error) {
        console.log(error)
      }
    },
    async getDrivers() {
      try {
        const { data } = await axiosIns.get('/drivers')
        this.drivers = data
      } catch (error) {
        console.log(error)
      }
    },

     async getTrucks() {
      try {
        const { data } = await axiosIns.get('/trucks')
        this.trucks = data
      } catch (error) {
        console.log(error)
      }
    },

    withTitle(index, f) {
      this.$swal({
        title: 'Produit déja ajouté au bon de sortie',
        text: 'Voulez vous vraiment ajouter +1 au quantité déja existe',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.removeNew(index)
          // eslint-disable-next-line operator-assignment
          f[0].qty = f[0].qty + 1
          this.$swal({
            icon: 'success',
            title: 'Quantité augmenté!',
            text: 'Quantité augmenté + 1!',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        } else {
          this.removeNew(index)
        }
      })
    },

    withTitle1(index, f) {
      this.$swal({
        title: 'Produit déja ajouté au bon de sortie',
        text: 'Voulez vous vraiment ajouter +1 au quantité déja existe',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.removeNew(index)
          // eslint-disable-next-line operator-assignment
          f[0].qty1 = f[0].qty1 + 1
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: 'Your file has been deleted.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        } else {
          this.removeNew(index)
        }
      })
    },

    async getExitVoucher() {
      const { data } = await axiosIns.get(
        `/exit-vouchers/details/${this.$route.params.id}/`,
      )
      console.log(data.date)
      this.invoice = data
      this.createdDate = data.date
      this.invoice.states = this.invoice.states.map(state => state.id)
      if (this.invoice != null) {
        axiosIns
          .post('/exit-vouchers-products/details/', {
            exit_voucher: this.invoice.id,
          })
          .then(res => {
            const result = res.data
            for (let i = 0; i < result.length; i += 1) {
              const itemFormBlankItem = {
                produit: result[i],
                qty: result[i].quantity,
                old_quantity: result[i].quantity,
              }

              this.invoiceData.items.push(
                JSON.parse(JSON.stringify(itemFormBlankItem)),
              )
            }
            this.isLoading = false
          })
      }
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },

    async update() {
      this.isLoading = true
      const convertedProducts = this.invoiceDataNew.items.map(product => ({
        id: product.produit.id, qty1: product.qty1, price_ttc: product.produit.sales_price_ttc, vat: product.produit.vat,
      }))
      const convertedProductsOld = this.invoiceData.items.map(product => ({
        id: product.produit.id, qty: product.qty, price_ttc: product.produit.price_ttc, vat: product.produit.vat,
      }))
      let truck
      if (this.invoice.truck) {
        truck = this.invoice.truck.id
      } else {
        truck = null
      } 
        await axiosIns
        .post('/exit-vouchers/update/', {
          reference: this.generate,
          date_start: this.invoice.date_start,
          date_end: this.invoice.date_end,
          date: this.createdDate,
          total_ht: this.somme.toFixed(3),
          total_ttc: this.sommeTTC,
          products: convertedProducts,
          products_old: convertedProductsOld,
          id: this.invoice.id,
          driver: this.invoice.driver.id,
          truck: truck,
          states: this.invoice.states,
          removed_items: this.removedItem,
        })
        .then(() => {
          this.$router.push({ name: 'exit-vouchers' })
          this.showToast(
            'success',
            'top-center',
            'Bon de sorite ajouté avec avec succès',
          )
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false

          this.showToast('danger', 'top-center', error.toString())
        })
    },

    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden'
      this.invoiceDataNew.items.push(
        JSON.parse(JSON.stringify(this.itemFormBlankItemNew)),
      )

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },

    async removeOld(index, item) {
      this.removedItem.push(item)
      this.invoiceData.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },

    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        // this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
  setup() {
    // UnRegister on leave

    const itemFormBlankItem = {
      produit: {
        reference: '',
      },
      qty: 0,
    }
    const itemFormBlankItemNew = {
      produit: {
        reference: '',
      },
      qty1: 1,
    }
    const invoices = ref([])
    const current = new Date()

    const invoiceData = ref({
      id: '',
      driver: null,
      date: `${current.getFullYear()}-${
        current.getMonth() + 1
      }-${current.getDate()}`,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],
    })
    const invoiceDataNew = ref({
      id: '',
      driver: null,
      date: `${current.getFullYear()}-${
        current.getMonth() + 1
      }-${current.getDate()}`,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],
    })

    const products = ref([])
    axiosIns.get('/products/').then(response => {
      products.value = response.data
    })

    const remise = 0

    const updateItemForm = (index, val) => {
      const { cost, qty } = val
      invoiceData.value.items[index].cost = cost
      invoiceData.value.items[index].qty = qty
    }

    const paymentMethods = ['Bank Account', 'PayPal', 'UPI Transfer']

    return {
      invoiceData,
      invoiceDataNew,
      products,
      updateItemForm,
      itemFormBlankItemNew,
      itemFormBlankItem,
      paymentMethods,
      invoices,
      remise,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
